import React from 'react';
import { colors } from '../../../modules/colors';
import { FullWidthField, FullWidthFieldHeader } from '../../full-width-field';
import { UnderlinedLinkAlt } from '../../links';

export const HaveQuestions = () => (
  <section aria-label="have questions?">
    <FullWidthField backgroundColor={colors.MINT}>
      <FullWidthFieldHeader>
        Questions?
        <br />
        <UnderlinedLinkAlt href="mailto:hello@ownup.com">hello@ownup.com</UnderlinedLinkAlt>
        <br />
        <UnderlinedLinkAlt href="tel:844-947-2848">(844) 947-2848</UnderlinedLinkAlt>
      </FullWidthFieldHeader>
    </FullWidthField>
  </section>
);
